.sidebar-container {
    display: flex;
    max-width: 100%;
    padding: 0;
}

main {
    width: 100%;
    padding: 20px;
}

.sidebar {
    position: fixed;
    background: #fff;
    color: #0a0000;
    height: 100vh;
    max-width: 300px;
    /* transition: all 0.5s; */
    z-index: 101;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column
}

.height {
    border-right: 1px solid #d9d9d9;
    flex-grow: 1;
}

/* .sidebar::-webkit-scrollbar {
    display:none
    width: 10px;
  } */

/* .sidebar::-webkit-scrollbar {
  width: 11px;
} */
.sidebar {
    scrollbar-width: thin;
    scrollbar-color: rgb(0, 0, 0);
}

/* .sidebar::-webkit-scrollbar-track {
  background: rgb(0, 4, 7);
  border-radius: 6px;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: rgb(222, 227, 231);
  border-radius: 6px;
  border: 2px solid rgb(222, 227, 231);
} */

.top_section {
    display: flex;
    align-items: center;
    /* padding: 15px 15px; */
    padding: 18px 15px;
    border-bottom: 1px solid #d9d9d9;
}

.sidebar-closed .top_section {
    padding: 30px 15px;
}

.logo {
    margin: 15px 10px;
}

.bars {
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}

.top_section img {
    flex: 0 0 20%;
    width: 25%;
}

.top_section .right_text {
    flex: 0 0 53.4%;
    width: 53.4%;
    font-size: 30px;
    font-weight: 900;
    padding-left: 15px;
}

.top_section .bars {
    flex: 0 0 17.6%;
    width: 17.6%;
}

.link {
    display: flex;
    color: #060000;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
    position: relative;
}

.link:hover {
    background: rgb(148, 173, 189);
    color: #000;
    transition: all 0.5s;
    /* border-right: 2px solid #dc3545; */
}

.actives {
    background: rgb(155, 198, 224);
    color: #000;
}

.icon,
.link_text {
    font-size: 18px;
}

.Downicon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5%;
}

.Downicon.up {
    transform: translateY(-50%) rotate(180deg);
}

.user {
    width: 60px;
    position: absolute;
    background-color: transparent;
    top: 50%;
    right: 30px;
    padding: 13px 7px;
    border-radius: 10px;
    transform: translateY(-50%);
}

.user img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.user_wraper {
    position: relative;
    height: 86px;
}

/* .user_wraper .down_arrow{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: url(../../images/down-arrow.svg) no-repeat;
    back
} */
.user_wraper .down_arrow {
    width: 10px;
    height: 10px;
    background: url(../../images/down-arrow.svg) no-repeat;
    background-size: 100%;
    background-position: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.user_wraper .user_profile_list {
    position: absolute;
    display: none;
    top: 55px;
    right: -10px;
    width: 150px;
    background-color: #edeff3;
    list-style: none;
    padding: 0;
    z-index: 101;
}

.user_wraper .user_profile_list .up_item {
    padding: 10px 15px;
}

.user_wraper .user_profile_list .up_item:not(:last-child) {
    border-bottom: 1px solid #fff;
}

.user_wraper .user_profile_list .up_item {
    cursor: pointer;
}

.user_wraper .user_profile_list .up_item:hover {
    background-color: #cafee2;
    color: #01030a;
}

.user_wraper .user_profile_list a {
    text-decoration: none;
}

.up_item {
    /* background-color: #1A46B8; */
    color: rgb(5, 0, 0);
}

.user:hover .user_profile_list {
    display: block;
}

.user:hover .down_arrow {
    top: 30%;
    transform: rotate(180deg) translateY(-50%);
}

.user svg {
    width: 30px;
    height: 35px;
}

.menu_wrapper {
    width: 100%;
}

.top_navbar {
    background: white;
    height: 86px;
    width: 100%;
    color: rgb(6, 0, 0);
    position: fixed;
    z-index: 10;
    outline: 1px solid #d9d9d9;
}

.user_name {
    position: absolute;
    background-color: transparent;
    top: 50%;
    right: 85px;
    padding: 7px;
    border-radius: 10px;
    transform: translateY(-50%);
    color: #000;
    font-size: 18px;
}

.btn-close {
    margin: 0 !important;
}

.link:hover {
    text-decoration: none;
}

.sidebar-open * {
    transition: all 0.5s;
    z-index: 100;
}

.sidebar-closed .top_section .logo,
.sidebar-closed .top_section .right_text,
.sidebar-closed .link_text {
    display: none;
    transition: all 0.5s;
}

.navLinkWrapper {
    overflow-y: auto;
}

.clock {
    position: absolute;
    font-weight: bolder;
    font-size: 20px;
    width: 10%;
    margin-top: 2px;
    right: 10px;
}


@media (min-width: 200px) and (max-width: 320px) {
    .sidebar-open {
        min-width: 100% !important;
    }

    .clock {
        position: absolute;
        font-weight: bolder;
        font-size: 20px;
        width: 50%;
        margin-top: 5px;
        right: 50px !important;
        /* transform: translateX(-40); */
    }
}

@media (min-width: 321px) and (max-width: 768px) {
    /* .sidebar-open {
      min-width: 100% !important;
    }
    .top_section .right_text {
      flex: 0 0 71.4%;
      width: 71.4%;
    }
    .top_section svg {
      flex: 0 0 12%;
      width: 12%;
    } */
    .clock {
        position: absolute;
        font-weight: bolder;
        font-size: 20px;
        width: 60%;
        margin-top: 10px;
        right: 60px !important;
    }

}

@media (min-width: 769px) and (max-width: 1200px) {

    .clock {
        position: absolute;
        font-weight: bolder;
        font-size: 20px;
        width: 30%;
        margin-top: 2px;
        right: 60px !important;
    }
}

@media (min-width: 1201px) and (max-width: 1900px) {
    .clock {
        position: absolute;
        font-weight: bolder;
        font-size: 20px;
        width: 20%;
        margin-top: 3px;
        right: 12px;
    }

}

@media (min-width: 1901px) {
    .clock {
        position: absolute;
        font-weight: bolder;
        font-size: 20px;
        width: 10%;
        margin-top: 2px;
        right: 2px;
    }
}
