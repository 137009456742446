
.component-card {
    break-inside: avoid-column;
    margin-bottom: 20px;
}

.component-card:hover {
    color: #0a58ca;
    border-color: #0a5dc2;
}

.component-card:active {
    color: #0d822c;
    border-color: #0d822c;
}
