.cardIcon {
    margin: auto;
    font-size: 4rem;
    padding: 5px 5px;
}

.cardText {
    text-align: center;
}

.chkBtnCard {
    position: absolute !important;
    z-index: 1;
}

.editBtnCard {
    position: absolute !important;
    z-index: 1;
    right: 0;
    margin: 5px;
}

.fileIcon {
    width: 60px;
    margin: auto;
    margin-top: auto;
}

.media-card {
    transition: all 0.3s ease;
}

.media-card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.checked-card {
    margin-bottom: 5px;
    border-radius: 5px !important;
    position: relative !important;
    transition: all 0.3s ease-in-out !important;
    border: 5px solid greenyellow !important;
}

/*.checked-card::before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    background: linear-gradient(to right, green, white);*/
/*    opacity: 0.2;*/
/*    transition: all 0.3s ease-in-out;*/
/*}*/